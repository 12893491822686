h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "Times New Roman", Times, serif;
}
.bgImage {
  background-image: url("./resources/newsbg.jpg");
  padding-top: 20px;
  padding-bottom: 20px;
}

.bgImage2 {
  background-image: url("./resources/newsbg.jpg");
  padding-top: 20px;
  padding-bottom: 20px;
}

.bgImage .container {
  max-width: 85%;
}

.newsContainer {
  margin: 0 auto;
}

.newsCardBig {
  width: 98%;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 2px 2px 2px black;
  background-color: rgb(255, 254, 251);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
}

.newsCardBig Img {
  width: 95%;
  height: 400px;
  margin-top: 10px;
  border-radius: 10px;

  box-shadow: 2px 2px 2px rgb(134, 134, 134);
}

.authorInfo {
  display: flex;
  flex-direction: row;
  /* align-items: baseline; */
  justify-content: space-around;
  width: 70%;
  margin-top: 10px;
  font-size: 16px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 90%;
}
.content h4 {
  color: black;
  font-weight: 500;
  font-size: 28px;
}
.content p {
  font-size: 16px;
  font-weight: 500;
  color: #434242;
  text-align: justify;
  padding-top: 20px;
}

.newsCardBig .btn {
  font-weight: 500;
  font-size: 18px;
  /* padding: 8px; */
  padding: 0px 15px;
  background: #1d6587;
  border: 1px solid #00617d;
  border-radius: 23px;
  color: white;
}

.authorInfo p span i {
  padding-right: 5px;
  color: #ff00d9;
}

.categoryContainer {
  background: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 2px 2px 2px black;
}

.categoryContainer h4 {
  font-size: 22px;
  font-weight: 500;
  padding-left: 10px;
  color: black;
}
.categoryContainer ul {
  padding-bottom: 10px;
}

.categoryContainer ul li {
  padding-top: 5px;
  padding-bottom: 15px;
  padding-left: 15px;
  color: #3d3d3d;
  font-weight: 500;
  border-top: 1px solid #cccccc63;
  font-size: 18px;
  cursor: pointer;
}

.categoryContainer ul li:hover {
  background: #7979793b;
  font-weight: 600;
  border-radius: 5px;
}
.smallnewsCard {
  margin-top: 30px;
  display: flex;
}
.singleNewsCard img {
  height: 150px;
}

.singleNewsCard .authorInfo p {
  font-size: 14px;
}

.singleNewsCard .content h4 {
  font-size: 22px;
  line-height: 1;
}

.singleNewsCard .btn {
  font-weight: 500;
  font-size: 16px;
  /* padding: 8px; */
  padding: 0px 15px;
  background: none;
  border: 2px solid #00617d;
  border-radius: 5px;
  color: black;
  line-height: 30px;
}

.EditorContainer {
  background: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 2px 2px 2px black;
}

.EditorContainer h4 {
  text-align: center;
  color: black;
  font-weight: 500;
}

.ImageContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.inputContainer {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 10px;
}

.inputContainer label {
  font-size: 16px;
  margin-right: 10px;
  width: 15%;
}

.inputContainer input {
  padding-left: 10px;
  width: 85%;
}

.inputContainer .editor {
  padding-left: 10px;
  width: 85%;
  color: black;
  font-weight: 500;
  font-size: 16px;
}

.inputContainer .buttonContainer {
  width: 100%;
  text-align: center;
}

.inputContainer .buttonContainer hr {
  visibility: hidden;
}

.inputContainer .buttonContainer button {
  font-size: 18px;
  color: white;
  background: rgb(253, 110, 58);
  border-radius: 5px;
  padding: 10px;
  transition: all 0.2s ease-in-out;
}

.inputContainer .buttonContainer button:hover {
  background: rgb(209, 77, 29);
}

.newsCardBig .backButton {
  width: 100%;
  padding: 10px;
  margin-left: 3%;
  display: flex;
  justify-content: space-between;
  padding-right: 50px;
  padding-bottom: 24px;
}

.newsCardBig .backButton h3 {
  width: 85%;
  color: black;
  font-size: 28px;
  font-weight: 500;
}

.newsCardBig .hr {
  border-top: 1px solid #c2c2c2;
  width: 90%;
}

.authorInfo .label {
  font-weight: 500;
  color: black;
  padding-right: 10px;
}

.backButton button {
  background: rgb(240, 234, 234);
  border: 1px solid rgb(224, 224, 224);
  color: black;
  padding: 10px;
  font-weight: 600;
  transition: 0.2s all ease-in-out;
}

.backButton button:hover {
  background: rgb(189, 189, 189);
}

.singleBlogPage .content {
  padding-top: 25px;
  text-align: justify;
}
.blogList {
  padding-top: 15px;
  padding-bottom: 30px;
}
.blogList table {
  width: 90%;
  margin: 0 auto;
}

.newsButtonContainer {
  display: flex;
  justify-content: space-between;
}

.newsButtonContainer button {
  font-size: 16px;
  padding: 0px 11px;
}
