@import url(https://fonts.maateen.me/adorsho-lipi/font.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

body {
  font-family: "Times New Roman", sans-serif;
  font-weight: 600;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.navbar-collapse {
  padding-top: 2%;
}

.nav-logo {
  height: 60px;
  width: 80%;
}

.btn2 {
  line-height: 36px;
}

.navbar-brand {
  margin-right: 0px;
}

.aboutUssectionDesign1 {
  width: 50%;
  border: 1px solid #17c788;
}

.managementdesign1 img {
  height: 272px;
}

.locattionMapDesign1 img {
  height: 462px;
}

.locattionMapDesign1 img:hover {
  opacity: 0.7;
}

.birdsEyeImage1 img {
  height: 650px;
}

.ContactAddress {
  text-align: center;
}

.contactHeading {
  font-size: 20px;
  color: #484545;
}

.contactInfo {
  font-size: 16px;
  color: #484545;
}

.fancybox {
  z-index: 200000000;
}

.fancybox img {
  border-radius: 12px;
}

.text-muted {
  color: red !important;
  font-size: 16px;
}
.LoginMainContainer {
  -webkit-justify-content: center;
          justify-content: center;
}

.LoginContainer {
  max-width: 70%;
  box-shadow: 2px 2px 4px black;
  padding: 4%;
  margin-bottom: 2%;
  border-radius: 10px;
  margin-top: 2%;
}

.LoginContainer h4 {
  text-align: center;
  color: #262927;
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }

  .mr-auto {
    padding-left: 90px;
  }
}

.floating_icons {
  top: 50%;
  left: 0;
  position: fixed;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 11000;
}

.floating_icons ul {
  padding: 0;
  padding: initial;
}

.photoGallery img {
  width: 100%;
  height: 300px;
  padding-bottom: 5%;
}

.floating_icons ul li {
  height: 40px;
  width: 40px;
  list-style-type: none;
  padding-left: 12px;
  padding-top: 6px;
  margin-top: 5px;
}

.floating_icons ul li a {
  color: white;
}

.floating_icons ul li:hover {
  padding-left: 30px;
  width: 80px;
  transition: 1s;
}

.facebook {
  background-color: #3b5998;
}

.twitter {
  background: #55acee;
}

.youtube {
  background: #ff0000;
}

.linkedin {
  background: #007bb5;
}

@media (min-width: 1201px) and (max-width: 1400px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 15px;
    padding: 6px 18px;
  }
}

.topicText {
  margin: 10px;
  padding: 7px;
  font-size: 20px;
  color: #565555;
  text-shadow: 0 0 black;
}

.formEditContainer label {
  width: 30%;
}

.formEditContainer input {
  width: 70%;
}

@media (max-width: 1200px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 13px;
    padding: 6px 14px;
  }
  .navbar-collapse .btn {
    padding: 0px 14px;
    font-size: 12px;
  }
}

.topBanner {
  height: 130px;
  width: 100%;
  background: red;

  padding-top: 1.5%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.topBanner img {
  height: 90px;
  width: 16%;
}
.topBanner .textBox p {
  font-size: 96px;
  color: white;
  letter-spacing: 10px;
  padding-left: 8px;
  padding-top: 7px;
  font-family: "AdorshoLipi", sans-serif;
}

.topBanner .textBox h3 {
  font-size: 18px;
  color: white;
  padding-bottom: 15px;
  padding-left: 13%;
  font-family: "AdorshoLipi", sans-serif;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.bgImage {
  background-image: url(/static/media/newsbg.ce52ce7e.jpg);
  padding-top: 20px;
  padding-bottom: 20px;
}
.optionContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  background: #f4f4f4;
  border-radius: 10px;
  box-shadow: 2px 2px 5px black;
  margin-top: 30px;
  margin-bottom: 30px;
}

.optionContainer h3 {
  font-size: 24px;
  font-family: inherit;
  color: white;
  font-weight: 500;
  text-align: center;
  background: #676464;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 20px;
}

.optionContainer .btn {
  border-bottom: 1px solid gray;
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px;
  font-size: 16px;
  text-transform: uppercase;
  text-align: left;
  transition: all 0.2s ease-in;
}

.optionContainer .btn:hover {
  background: rgb(83, 83, 83);
  color: rgb(243, 240, 240);
  margin: 0px;
  border-bottom: 0px;
}

.itemContainer {
  border-radius: 10px;
  width: 100%;
  background: #f4f4f4;
  border-radius: 10px;
  box-shadow: 2px 2px 5px black;
  padding: 10px;
  margin-top: 30px;
}

.EditAboutPageContainer {
  width: 90%;
  margin: 0 auto;
}

.EditAboutPageContainer h3 {
  font-size: 24px;
  font-family: inherit;
  color: rgb(42, 42, 42);
  font-weight: 500;
  text-align: center;
  margin-top: 20px;
}

.EditAboutPageContainer h4 {
  font-family: inherit;
  font-size: 20px;
  color: rgb(36, 36, 36);
}

.EditAboutPageContainer textarea::-webkit-input-placeholder {
  color: gray;
}

.EditAboutPageContainer label {
  font-size: 20px;
  font-family: inherit;
  color: rgb(42, 42, 42);
  font-weight: 500;
}

.EditAboutPageContainer .ImageContainer {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 10px;
}
.EditAboutPageContainer button {
  width: 50%;
  margin-left: 25%;
  /* margin-right: 25%; */
  margin-top: 5%;
  margin-bottom: 3%;
  font-size: 20px;
  letter-spacing: 2px;
}

.formContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 3%;
}

.formContainer button {
  margin: 0px;
  width: auto;
}

.toastContainer {
  position: fixed;
  right: 15%;
  top: 20%;
  z-index: 1000000;
}

.toastContainer .toastPart {
  width: 270px;
  background: rgb(10, 125, 233);
  color: white;
  font-size: 16px;
}

.toastContainer .toastPart button {
  width: 25%;
}

.toastContainer .toastPart .toast-header {
  background: none;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  color: white;
  font-size: 18px;
}

.EditAboutPageContainer textarea {
  padding: 30px;
  text-align: justify;
  font-size: 18px;
}

.DeleteImageContainer {
  width: 70%;
}
.DeleteImageContainer img {
  width: 100%;
  height: 250px;
}

.EditorContainer h4 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}

.inputContainer2 {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  height: 250px;
  margin-bottom: 12%;
}

.inputContainer2 .editor2 {
  width: 100%;
  color: black;
  font-weight: 500;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "Times New Roman", Times, serif;
}
.bgImage {
  background-image: url(/static/media/newsbg.ce52ce7e.jpg);
  padding-top: 20px;
  padding-bottom: 20px;
}

.bgImage2 {
  background-image: url(/static/media/newsbg.ce52ce7e.jpg);
  padding-top: 20px;
  padding-bottom: 20px;
}

.bgImage .container {
  max-width: 85%;
}

.newsContainer {
  margin: 0 auto;
}

.newsCardBig {
  width: 98%;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 2px 2px 2px black;
  background-color: rgb(255, 254, 251);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 5%;
}

.newsCardBig Img {
  width: 95%;
  height: 400px;
  margin-top: 10px;
  border-radius: 10px;

  box-shadow: 2px 2px 2px rgb(134, 134, 134);
}

.authorInfo {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  /* align-items: baseline; */
  -webkit-justify-content: space-around;
          justify-content: space-around;
  width: 70%;
  margin-top: 10px;
  font-size: 16px;
}

.content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  width: 90%;
}
.content h4 {
  color: black;
  font-weight: 500;
  font-size: 28px;
}
.content p {
  font-size: 16px;
  font-weight: 500;
  color: #434242;
  text-align: justify;
  padding-top: 20px;
}

.newsCardBig .btn {
  font-weight: 500;
  font-size: 18px;
  /* padding: 8px; */
  padding: 0px 15px;
  background: #1d6587;
  border: 1px solid #00617d;
  border-radius: 23px;
  color: white;
}

.authorInfo p span i {
  padding-right: 5px;
  color: #ff00d9;
}

.categoryContainer {
  background: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 2px 2px 2px black;
}

.categoryContainer h4 {
  font-size: 22px;
  font-weight: 500;
  padding-left: 10px;
  color: black;
}
.categoryContainer ul {
  padding-bottom: 10px;
}

.categoryContainer ul li {
  padding-top: 5px;
  padding-bottom: 15px;
  padding-left: 15px;
  color: #3d3d3d;
  font-weight: 500;
  border-top: 1px solid #cccccc63;
  font-size: 18px;
  cursor: pointer;
}

.categoryContainer ul li:hover {
  background: #7979793b;
  font-weight: 600;
  border-radius: 5px;
}
.smallnewsCard {
  margin-top: 30px;
  display: -webkit-flex;
  display: flex;
}
.singleNewsCard img {
  height: 150px;
}

.singleNewsCard .authorInfo p {
  font-size: 14px;
}

.singleNewsCard .content h4 {
  font-size: 22px;
  line-height: 1;
}

.singleNewsCard .btn {
  font-weight: 500;
  font-size: 16px;
  /* padding: 8px; */
  padding: 0px 15px;
  background: none;
  border: 2px solid #00617d;
  border-radius: 5px;
  color: black;
  line-height: 30px;
}

.EditorContainer {
  background: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 2px 2px 2px black;
}

.EditorContainer h4 {
  text-align: center;
  color: black;
  font-weight: 500;
}

.ImageContainer {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 10px;
}

.inputContainer {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding: 10px;
}

.inputContainer label {
  font-size: 16px;
  margin-right: 10px;
  width: 15%;
}

.inputContainer input {
  padding-left: 10px;
  width: 85%;
}

.inputContainer .editor {
  padding-left: 10px;
  width: 85%;
  color: black;
  font-weight: 500;
  font-size: 16px;
}

.inputContainer .buttonContainer {
  width: 100%;
  text-align: center;
}

.inputContainer .buttonContainer hr {
  visibility: hidden;
}

.inputContainer .buttonContainer button {
  font-size: 18px;
  color: white;
  background: rgb(253, 110, 58);
  border-radius: 5px;
  padding: 10px;
  transition: all 0.2s ease-in-out;
}

.inputContainer .buttonContainer button:hover {
  background: rgb(209, 77, 29);
}

.newsCardBig .backButton {
  width: 100%;
  padding: 10px;
  margin-left: 3%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-right: 50px;
  padding-bottom: 24px;
}

.newsCardBig .backButton h3 {
  width: 85%;
  color: black;
  font-size: 28px;
  font-weight: 500;
}

.newsCardBig .hr {
  border-top: 1px solid #c2c2c2;
  width: 90%;
}

.authorInfo .label {
  font-weight: 500;
  color: black;
  padding-right: 10px;
}

.backButton button {
  background: rgb(240, 234, 234);
  border: 1px solid rgb(224, 224, 224);
  color: black;
  padding: 10px;
  font-weight: 600;
  transition: 0.2s all ease-in-out;
}

.backButton button:hover {
  background: rgb(189, 189, 189);
}

.singleBlogPage .content {
  padding-top: 25px;
  text-align: justify;
}
.blogList {
  padding-top: 15px;
  padding-bottom: 30px;
}
.blogList table {
  width: 90%;
  margin: 0 auto;
}

.newsButtonContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.newsButtonContainer button {
  font-size: 16px;
  padding: 0px 11px;
}

